let shopHostname = window.location.hostname;
if (shopHostname.startsWith("client") || shopHostname.startsWith("localhost")) {
  shopHostname = "https://new.staging.carus-verlag.com";
} else {
  shopHostname = "https://www.carus-verlag.com";
}

export default {
  // product display information
  product: {
    name: "carus performance portal",
    version: "24.11.21.0",
    gitsha: "ab125c149ff7d87b63d43f3ab755c12ac75b96c3",
    shopHostname,
  },

};
